@import "vars";

.btn-default {
  display: block;
  border-radius: 20px;
  background-color: $yellow !important;
  color: $blue;
  padding: 10px 25px;
  width: 100%;
  font-size: 18px !important;
  font-weight: 500;
  text-align: center;
  transition: all 0.15s ease-out;
  height: 46px;

  &.arrow {
    padding: 20px 30px;
    height: 56px;
    text-align: left;
    background: url("../../img/arrow.svg") no-repeat 90% center;
  }

  &.blue {
    background-color: $blue !important;
    color: $yellow;
    padding-top: 15px;
    height: 44px;

    &:hover {
      font-size: 19px !important;
    }
  }

  &:hover {
    font-size: 20px !important;

    &.arrow {
      text-align: left;
      background: url("../../img/arrow.svg") no-repeat 92% center;
    }
  }
}

@media only screen and (max-width:990px) {
  .btn-default {
    font-size: 14px !important;
    height: auto;

    &.blue {
      height: auto;
    }
  }
}