@import '../../assets/scss/common/vars';

.slider {
  .swiper {
    width: 100%;
    min-height: 200px;

    .swiper-slide {
      overflow: hidden;
      border-radius: 20px;

      img {
        width: 100%;
        height: 230px;

        &.mobile {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .slider {
    .swiper {
      .swiper-slide {
        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .slider {
    .swiper {
      .swiper-slide {
        height: calc(80vw);

        img {
          height: calc(80vw);
          object-fit: cover;

          &.mobile {
            display: block;
          }

          &.desktop {
            display: none;
          }
        }
      }
    }
  }
}