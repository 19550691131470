@import "../../assets/scss/common/vars";

.faq {
  .faq-row {
    border-radius: 16px;
    border: 1px solid #DCE1ED;
    padding: 0 !important;
    overflow: hidden;
    margin-bottom: 25px;
  }

  .row-title {
    position: relative;
    padding: 35px 40px !important;
    background-color: $yellow;

    .icon-wrapper {
      top: 30px !important;
      right: 25px !important;
    }

    .row-title-text {
      color: $blue;
      font-weight: 700;
    }

    &::before {
      content: " ";
      position: absolute;
      background-color: transparent;
      top: 0;
      left: 0;
      right: 0;
      height: 2000px;
      z-index: 0;
      transition: all 0.3s ease-out;
    }
  }

  .row-content {
    background-color: $gray;

    a {
      color: $blue;
      text-decoration: underline;
      font-size: 14px;
      line-height: 20px;
    }

    h2 {
      margin: 35px 0 15px;
      font-size: 14px;
      line-height: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    ul {
      list-style: disc;
      padding-left: 25px;
    }

    video {
      display: block;
      margin: 20px auto;
    }

    p, li {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width:990px) {
  .faq {
    .template {
      .wrapper {
        padding: 30px 15px;
      }
    }

    .row-content {
      a {
        word-break: break-all;
      }

      video {
        width: 100%;
        height: 150px;
      }
    }
  }
}
