@import "vars";

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 12px 15px;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger.is-active {
  .hamburger-inner {
    background-color: $yellow;
    &::before {
      background-color: $yellow;
    }
    &::after {
      background-color: $yellow;
    }
  }
}
.hamburger-box {
  width: 36px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 36px;
  height: 4px;
  //box-shadow: 1px 1.5px 5px 0 rgba( 0, 0, 0, 0.35 );
  background-color: $yellow;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  &::before {
    width: 36px;
    height: 4px;
    //box-shadow: 1px 1.5px 5px 0 rgba( 0, 0, 0, 0.35 );
    background-color: $yellow;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    content: "";
    display: block;
    top: -10px;
  }
  &::after {
    width: 36px;
    height: 4px;
    //box-shadow: 1px 1.5px 5px 0 rgba( 0, 0, 0, 0.35 );
    background-color: $yellow;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    content: "";
    display: block;
    bottom: -10px;
  }
}
.hamburger--spring {
  .hamburger-inner {
    top: 2px;
    transition: background-color 0s 0.13s linear;
    &::before {
      top: 10px;
      transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    &::after {
      top: 20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}
.hamburger--spring.is-active {
  .hamburger-inner {
    transition-delay: 0.22s;
    background-color: transparent !important;
    box-shadow: none;
    &::before {
      top: 0;
      transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 10px, 0) rotate(45deg);
    }
    &::after {
      top: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 10px, 0) rotate(-45deg);
    }
  }
}
