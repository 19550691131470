@import "vars";

.full {
  .template {
    .ct-sidebar {
      position: fixed;
      width: auto;
      top: 25px;
      left: 50%;
      margin-left: 450px;
      z-index: 200;

      .sidebar {
        min-height: auto;
        padding-bottom: 0;
        width: auto;
        background-color: transparent;

        & > header {
          height: auto;
          padding-top: 0;
          background-image: none;
          background-color: transparent;

          p, h2 {
            display: none;
          }
        }

        nav, footer, .btn-minimize {
          display: none;
        }

        .profile-pic {
          width: 60px;
          height: 60px;
        }
      }
    }

    .ct-content {
      height: calc(100vh - 203px);
      width: 100%;
    }
  }
}

.template {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding-bottom: 40px;

  .ct-sidebar {
    width: calc(100% - 940px - 25px);
  }

  .header-inner {
    padding-left: 10px;

    h1 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $blue;
      font-size: 23px;
      font-weight: 500;
      letter-spacing: 0;
      margin: 15px 0 10px;

      img {
        margin-right: 15px;
      }

      span {
        display: block;
        margin-top: 5px;
      }
    }
  }

  .ct-content {
    width: 940px;
  }

  .reports {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;

    a {
      background-color: #ffffff;
      border-radius: 20px;
      width: 100%;
      height: 270px;
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .wrapper {
    border-radius: 20px;
    background-color: #ffffff;
    min-height: 520px;
    padding: 40px;
    overflow: hidden;

    h2 {
      color: $blue;
      font-weight: 700;
      margin-bottom: 10px;
    }

    form {
      padding-left: 40px;

      h2 {
        margin-left: -40px;
      }

      hr {
        opacity: 0.3;
        margin-left: -40px;
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid $blue;
        padding: 0;
      }
    }

    &.iframe {
      padding: 0;
      height: 100%;

      .report {
        width: 100%;
        height: 100%;
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    &.loading {
      display: flex;
      align-items: center;
      justify-content: center;

      .ico-loading {
        width: 45px;
        height: 45px;
      }
    }
  }
}

.ico-rotate {
  display: none;
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 50px;
  height: 50px;

  svg {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .full {
    .template {
      .ct-sidebar {
        left: unset;
        margin-left: 0;
        right: 100px;
      }
    }
  }

  .template {
    .ct-sidebar {
      width: calc(100% - 700px - 25px);
    }

    .ct-content {
      width: 700px;
    }

    .reports {
      a {
        height: 220px;
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .full {
    .template {
      .ct-sidebar {
        display: block;
        right: 90px;
      }
    }
  }

  .template {
    margin-top: 20px;

    .ct-sidebar {
      display: none;
    }

    .ct-content {
      width: 100%;
    }

    .reports {
      margin-top: 20px;
      grid-template-columns: 1fr 1fr;
    }

    .wrapper {
      form {
        padding-left: 0;

        h2, hr {
          margin-left: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .template {
    .wrapper {
      border-radius: 10px;
    }

    .ct-content {
    }

    .reports {
      grid-template-columns: 1fr;
    }
  }

  .ico-rotate {
    display: block;
  }
}