@import "../../assets/scss/common/vars";

.legal-page {
  article {
    h2 {
      font-weight: 700;
      text-align: left;
      margin: 30px 0 25px;
      font-size: 22px;
      line-height: 30px;

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      line-height: 22px;
      margin-bottom: 15px;
    }

    a {
      color: $blue;
      text-decoration: underline;
    }

    table {
      border-spacing: 10px 50px;
      margin-bottom: 30px;

      tr, td, th {
        border: #000 solid 1px;
        padding: 5px;
        font-size: 20px;
        vertical-align: middle;
      }

      th {
        vertical-align: middle;
        color: #FFF;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .legal-page {
    .wrapper {
      padding: 20px !important;
    }

    article {
      h2 {
        font-size: 18px;
      }

      p, li, a {
        font-size: 14px;
      }

      table {
        p {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}