@media only screen and (max-width: 990px) {
  .dashboard {
    .template {
      .wrapper {
        background-color: transparent;
        padding: 0 0;

        a {
          margin-bottom: 20px;
          display: block;
          background-color: #ffffff;
          width: 100%;
          border-radius: 25px;

          img {
            display: block;
            margin: 0 auto;
          }
        }
      };
    }
  }
}