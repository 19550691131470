@import "vars";

.vista {
  font-family: "neusa-next-std", sans-serif;
  font-size: 16px;
  background-color: $gray;

  p, li, blockquote {
    font-family: "neusa-next-std", sans-serif;
    font-size: 16px;
    line-height: 22px;
  }

  h1, h2, h3, h4 {
    font-family: "neusa-next-std", sans-serif;
  }

  button {
    font-family: "neusa-next-std", sans-serif;
    font-size: 16px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  a {
    font-family: "neusa-next-std", sans-serif;
    text-decoration: none;
    font-size: 16px;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    font-weight: 700;
  }

  &.logged {
    padding-top: 113px;
  }

  .container {
    max-width: 1205px;

    &.full {
      max-width: calc(100% - 60px);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .vista {
    .container {
      max-width: 970px;

      &.full {
        max-width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .vista {
    .container {
      &.full {
        max-width: 100%;
      }
    }
  }

  @media screen and (orientation: landscape) {
    .report-body {
      #header {
        display: none;
      }

      .full .template .ct-sidebar {
        display: none;
      }

      .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .template {
        margin-top: 0;

        .wrapper {
          border-radius: 0;
        }
      }

      &.logged {
        padding-top: 0;
      }
    }

    .ico-rotate {
      display: none !important;
    }
  }
}