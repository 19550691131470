#general-loading {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.55);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  svg {
    display: block;
    width: 60px;
    height: 60px;
    animation: turnTwo 1.5s infinite;
  }

  &.show {
    visibility: visible;
    opacity: 1;
  }

  .turn-01 { animation-name: turnOne;}
  .turn-02 { animation-name: turnTwo;}
  .turn-03 { animation-name: turnThree;}
  .turn-04 { animation-name: turnFour;}
  .turn-05 { animation-name: turnFive;}

  @supports (-ms-ime-align:auto) {
    background-color: rgba(255,255,255,0.85);
    svg {
      margin-top: 40vh;
    }
  }
}

.ico-loading {
  width: 100%;
  height: auto;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}

@keyframes turnOne {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(180deg);}
}

@keyframes turnTwo {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@keyframes turnThree {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(540deg);}
}

@keyframes turnFour{
  0% {transform: rotate(0deg);}
  100% {transform: rotate(720deg);}
}

@keyframes turnFive {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(900deg);}
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: scale(0.5) rotateY(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotateX(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}