@import "../../assets/scss/common/vars";

.vista-login {
  background: url("../../assets/img/bg-login.png") no-repeat center;
  background-size: cover;
  height: 100vh;

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .company {
      margin-top: 10vh;
      margin-left: 10vw;

      h1 {
        margin-top: 20px;
        font-size: 30px;
        line-height: 38px;
        color: #ffffff;
        margin-left: 150px;

        strong {
          color: $yellow;
          text-decoration: underline;
        };
      }
    }
  }

  .box-form {
    background-color: $blue;
    border-radius: 25px;
    padding: 35px;
    margin-right: 10vw;
    color: #ffffff;
    max-width: 365px;

    h3 {
      font-size: 45px;
      font-weight: 500;
      margin-bottom: 20px;

      span {
        color: $yellow;
      }
    }

    p {
      margin-bottom: 20px;
    }

    .link {
      margin-top: 40px;
      color: #ffffff;
      font-weight: 300;

      a {
        display: block;
        width: 100%;
        font-size: 14px;
        text-align: right;
        color: $yellow;
      }
    }
  }
}

@media only screen and (max-width:768px) {
  .vista-login {
    height: auto;
    padding-bottom: 40px;

    section {
      display: block;

      .company {
        margin: 0;
        padding: 30px 25px;

        img {
          display: block;
          width: 60vw;
          margin: 0 auto 40px;
        }

        h1 {
          margin-left: 0;
          margin-bottom: 30px;
          text-align: center;
        }
      }
    }

    .box-form {
      margin: 0 15px;
      width: calc(100% - 30px);

      h3 {
        font-size: 38px;
      }
    }
  }
}