@import "../assets/scss/common/vars";

.logged {
  header {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 113px;
  transition: all 0.5s ease-out;
  z-index: 100;
  visibility: hidden;
  transform: translateY(-300px);
  opacity: 0;
}

.ctHeader {
  position: relative;
  padding: 10px 0;
  background-color: $blue;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  height: 113px;
}

.menu {
  background-color: $blue;
  visibility: hidden;
  transform: translateY(-300px);
  opacity: 0;
  z-index: 5;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
  }

  li {
    padding: 0 5px;
    border-bottom: 1px solid $yellow;

    &:last-child {
      border-bottom: none;
    }
  }

  a, button {
    display: block;
    font-size: 20px !important;
    font-weight: 400;
    padding: 14px 20px;
    color: #ffffff;
    letter-spacing: 0.5px;
    text-align: left;
    text-decoration: none;

    &:hover, .active {
      text-decoration: none !important;
      font-weight: 500;
      color: $yellow;
    }
  }
}

.menuOpen {
  transition: all 0.3s ease-out;
  opacity: 1 !important;
  visibility: visible !important;
  transform: translateY(0);
}

.logo {
  display: block;
  margin-top: 6px;
  width: 151px;
  height: auto;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }
}

.ctMenu {
  display: flex;
  justify-content: space-between;
}
