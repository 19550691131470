@import '../../assets/scss/common/vars';

.container-upload {
  .thumbsContainer {
    margin-top: 0 !important;
    display: none;

    & > div {
      border-radius: 50%;
      background-color: $yellow;
      overflow: hidden;

      img {
        display: block;
      }
    }
  }

  .thumb {
    width: 100px;
    height: 100px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
    }
  }

  .dropzone {
    background: $blue;
    padding: 15px 25px 10px;
    border-radius: 20px;
    cursor: pointer;
    height: 50px;

    p {
      color: $yellow;
      font-weight: 500;
      text-align: center;
    }
  }

  .error-text {
    margin-top: 5px;
    font-size: 14px;
    color: darkred;
  }
}

@media only screen and (max-width: 990px) {
}