@import "../../assets/scss/common/vars";

.contact {
  .ct-content {
    h2 {
      text-align: center;
      font-size: 20px;
      line-height: 30px;
      color: #000000 !important;
      font-weight: 400 !important;
      margin: 30px 0 70px !important;

      strong {
        color: $blue;
        font-weight: 700;
      }
    }
  }

  .ct-info {
    display: flex;
    justify-content: center;
    align-items: center;

    .info {
      border-radius: 25px;
      background-color: $blue;
      padding: 20px;
      width: 35%;
      margin: 0 30px;

      h3 {
        color: #ffffff;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }

      ul {
        margin: 10px auto 0;
        padding-left: 0;

        li {
          margin-bottom: 15px;

          a {
            display: flex;
            justify-content: flex-start;
            color: #ffffff;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }

            img {
              margin-right: 10px;
            }

            p {
              font-size: 12px;
              line-height: 16px;

              strong {
                font-weight: 500;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width:990px) {
  .contact {
    .ct-content {
      h2 {
        margin-top: 0 !important;
        margin-bottom: 40px !important;
      }
    }

    .ct-info {
      display: block;

      .info {
        width: 100%;
        margin-bottom: 30px;
        margin: 0 0 30px 0;
      }
    }
  }
}