@import '../../assets/scss/common/vars';


.btn-maximize {
  position: fixed;
  top: 130px;
  left: 20px;
  width: 30px;
  height: 30px;
  background-color: $yellow !important;
  border-radius: 50%;
  z-index: 100;
  color: $blue;
  font-size: 30px !important;
  font-weight: 500 !important;
}

.sidebar {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  background-color: #ffffff;
  width: 220px;
  min-height: 568px;
  padding-bottom: 104px;

  & > header {
    background: url("../../assets/img/sidebar-top.png") no-repeat center $blue;
    background-size: cover;
    width: 100%;
    height: 104px;
    padding-top: 40px;
    color: $blue;

    .btn-minimize {
      position: absolute;
      top: 13px;
      right: 20px;
      width: 20px;
      height: 20px;
      background-color: $yellow;
      border-radius: 50%;

      &::after {
        content: " ";
        position: absolute;
        top: 8px;
        left: 4px;
        width: 12px;
        height: 3px;
        background-color: $blue;
      }
    }

    p {
      text-align: center;
      margin-bottom: 2px;
      font-size: 14px;
    }

    h2 {
      font-size: 20px;
      letter-spacing: 0;
      font-weight: 700;
      text-align: center;
      margin-bottom: 2px;
    }
  }

  .profile-pic {
    cursor: pointer;
    width: 120px;
    height: 120px;
    margin: 0 auto 10px;
    border-radius: 50%;
    background-color: $yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    //transition: all 0.5s;
    //transform-origin: right;
  }

  nav {
    margin-top: 170px;
    width: 100%;
    margin-bottom: 10px;

    header {
      background-color: $yellow;
      padding: 12px 15px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    a {
      position: relative;
      margin: 0 10px 3px;
      padding: 5px 15px;
      display: block;
      font-size: 12px;
      border-bottom: 1px solid $yellow;
      color: $blue;
      transition: all 0.15s ease-out;

      &:hover, &.active {
        font-weight: 700;

        &::before {
          position: absolute;
          content: "> ";
          left: 5px;
          top: 3px;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    .btn-exit {
      background-color: $yellow;
      padding: 15px 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;

      img {
        margin-right: 10px;
      }

      span {
        display: block;
        margin-top: 5px;
      }
    }

    .social {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;

      li {
        margin: 0 10px;

        a {

          svg {
            transition: all 0.15s ease-out;
          }

          &:hover {

          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .sidebar {
    min-height: 488px;
  }
}